<script setup>
import { useRouter, userDataLoading } from "@/utils/vue_helpers";
import { ref, computed } from "vue";

const props = defineProps({
  text: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    default: null,
  },
  number: {
    type: [String, Number],
    default: null,
  },
  vuetifyColor: {
    type: Object,
    default: null,
  },
  route: {
    type: Object,
    default: null,
  },
});

const { router } = useRouter();

const hovered = ref(false);
const textColor = computed(() => {
  if (hovered.value) {
    return "white--text";
  }
  return `white--text`;
});
const backgroundColor = computed(() => {
  if (hovered.value) {
    return props.vuetifyColor.darken2;
  }
  return `${props.vuetifyColor.base} `;
});

const commonStyles = {
  transition: "color 200ms ease-in-out, background-color 200ms ease-in-out",
};

function onClicked() {
  const routeNotEmpty = !!props.route;
  if (routeNotEmpty) {
    router.value.push(props.route);
  }
}
</script>

<template>
  <v-card
    @mouseover="hovered = true"
    @mouseout="hovered = false"
    @click="onClicked"
    class="rounded-xl"
    :class="[backgroundColor]"
    :style="{
      background: backgroundColor,
      height: '100%',
      ...commonStyles,
    }"
  >
    <v-container fluid class="" :style="{ position: 'relative' }">
      <v-row :no-gutters="$mobileBreakpoint" justify="space-between" align="center" class="px-4">
        <v-col cols="auto" class="d-flex align-center">
          <div :class="['text-md-h5 text-h6', textColor]">{{ props.text }}</div>
        </v-col>

        <v-col cols="auto" :class="{ 'd-flex align-center': true }">
          <v-icon size="40" :class="[textColor]" :style="{ ...commonStyles }">{{
            props.icon
          }}</v-icon>
        </v-col>
      </v-row>

      <v-row justify="space-between" align="start" class="px-4">
        <v-col cols="auto">
          <v-skeleton-loader
            v-if="userDataLoading"
            type="image"
            width="50"
            height="50"
            dark
          />
          <div
            v-else
            :class="[textColor, 'text-h3']"
            style="
               {
                ...commonStyles;
              }
            "
          >
            {{ props.number }}
          </div>
        </v-col>

        <v-col v-if="$scopedSlots.additionalContent" cols="auto">
          <slot name="additionalContent" :hovered="hovered" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style lang="scss"></style>
