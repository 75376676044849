<script setup>
import AccountInformationItem from "./AccountInformationItem.vue";
import {
  userData,
  userDataLoading,
  getWorkflowStatusFromName,
  useRouter,
  supportingFormDataLoading,
fetchUserDataOnMount,
} from "@/utils/vue_helpers";
import { getReceiptsFromBills } from "@/utils/functions";
import colors from "vuetify/lib/util/colors";
import { routes } from "@/router/routes";
import { computed, watchEffect, reactive } from "vue";

fetchUserDataOnMount();

const { router } = useRouter();

const accountInfoData = reactive({
  numberOfProperties: null,
  numberOfBusinesses: null,
  numberOfReceipts: null,
  numberOfBills: null,
  numberOfUnpaidBills: null,
  numberOfPaidBills: null,
  numberOfVehicles: null,
  numberOfHouses: null,
});
watchEffect(() => {
  const bills = userData.value?.bills ?? null;
  accountInfoData.numberOfProperties = userData.value?.properties?.length ?? 0;
  accountInfoData.numberOfBusinesses = userData.value?.businesses?.length ?? 0;
  accountInfoData.numberOfReceipts = getReceiptsFromBills(bills)?.length ?? 0;
  accountInfoData.numberOfBills = bills?.length ?? 0;
  accountInfoData.numberOfVehicles = userData.value?.vehicles?.length ?? 0;
  accountInfoData.numberOfHouses = userData.value?.houses?.length ?? 0;
  accountInfoData.numberOfUnpaidBills = 0;
  accountInfoData.numberOfPaidBills = 0;
  if (!supportingFormDataLoading.value) {
    const paidWfStatus = getWorkflowStatusFromName("Paid");
    const unpaidWfStatus = getWorkflowStatusFromName("Unpaid");
    bills?.forEach((bill) => {
      const billIsPaid = bill.workflow_status_id === paidWfStatus.id;
      const billIsUnpaid = bill.workflow_status_id === unpaidWfStatus.id;
      if (billIsPaid) {
        accountInfoData.numberOfPaidBills += 1;
      } else if (billIsUnpaid) {
        accountInfoData.numberOfUnpaidBills += 1;
      }
    });
  }
});

const accountInformation = computed(() => {
  return [
    {
      text: "My Plots",
      icon: "mdi-home-city",
      number: accountInfoData.numberOfProperties,
      vuetifyColor: colors.blue,
      route: routes.viewProperties,
    },
    {
      text: "My Businesses",
      icon: "mdi-briefcase",
      number: accountInfoData.numberOfBusinesses,
      vuetifyColor: colors.orange,
      route: routes.viewBusinesses,
    },
  ];
});

const receiptItem = computed(() => {
  return {
    text: "My Receipts",
    icon: "mdi-receipt-text",
    number: accountInfoData.numberOfReceipts,
    vuetifyColor: colors.grey,
    route: routes.viewBillsWithQuery({
      tab: "paid",
    }),
  };
});

const vehicleItem = computed(() => {
  return {
    text: "My Vehicles",
    icon: "mdi-car-back",
    number: accountInfoData.numberOfVehicles,
    vuetifyColor: colors.green,
    route: routes.viewVehicles,
  };
});

const houseItem = computed(() => {
  return {
    text: "My Houses",
    icon: "mdi-home",
    number: accountInfoData.numberOfHouses,
    vuetifyColor: colors.blue,
    route: routes.viewHouses,
  };
});
const billsItem = computed(() => {
  return {
    text: "My Bills",
    icon: "mdi-bank",
    number: accountInfoData.numberOfBills,
    vuetifyColor: colors.red,
  };
});

function onPaidBillsClicked() {
  router.value.push(
    routes.viewBillsWithQuery({
      tab: "paid",
    })
  );
}

function onUnpaidBillsClicked() {
  router.value.push(
    routes.viewBillsWithQuery({
      tab: "unpaid",
    })
  );
}
const billsItemTextColor = `red--text`;
</script>

<template>
  <v-container fluid class="px-0 py-4">
    <v-row v-if="$mobileBreakpoint">
      <v-col
        cols="12"
        class="text-h6 primary--text font-weight-bold text-center"
      >
        Dashboard
      </v-col>
    </v-row>

    <v-row :no-gutter="$mobileBreakpoint" class="" :style="{}">
      <v-col
        cols="12"
        sm="6"
        md="4"
        class=""
        :style="{}"
        v-for="accountInformationItem in accountInformation"
      >
        <AccountInformationItem
          :text="accountInformationItem.text"
          :icon="accountInformationItem.icon"
          :number="accountInformationItem.number"
          :vuetifyColor="accountInformationItem.vuetifyColor"
          :route="accountInformationItem.route"
        >
        </AccountInformationItem>
      </v-col>

      <v-col cols="12" sm="6" md="4" :style="{}">
        <AccountInformationItem
          :text="billsItem.text"
          :icon="billsItem.icon"
          :number="billsItem.number"
          :vuetifyColor="billsItem.vuetifyColor"
          :route="routes.viewBills"
        >
          <template #additionalContent="{ hovered }">
            <v-container fluid class="pa-0" @click.stop="">
              <template v-if="supportingFormDataLoading || userDataLoading">
                <v-row dense justify="end">
                  <v-col cols="auto" class="pa-0">
                    <!-- <v-progress-circular indeterminate color="background" size="16" width="2" /> -->
                    <v-skeleton-loader
                      width="94"
                      height="20"
                      type="text"
                      class="skeleton-fill-height"
                      dark
                    />
                  </v-col>
                </v-row>
                <v-row dense justify="end" class="mt-3">
                  <v-col cols="auto" class="pa-0">
                    <!-- <v-progress-circular indeterminate color="background" class="mt-2" size="16"
                                                    width="2" /> -->
                    <v-skeleton-loader
                      width="94"
                      height="20"
                      type="text"
                      class="skeleton-fill-height"
                      dark
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row dense justify="end">
                  <v-col cols="auto" class="pa-0">
                    <v-btn
                      small
                      text
                      class="text-none white--text"
                      @click.prevent="onPaidBillsClicked"
                    >
                      Paid: {{ accountInfoData.numberOfPaidBills }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense justify="end">
                  <v-col cols="auto" class="pa-0">
                    <v-btn
                      small
                      text
                      class="text-none white--text"
                      @click.prevent="onUnpaidBillsClicked"
                    >
                      Unpaid: {{ accountInfoData.numberOfUnpaidBills }}
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </template>
        </AccountInformationItem>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        class="pt-4"
      >
        <AccountInformationItem v-bind="receiptItem"> </AccountInformationItem>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        class="pt-4"
        :style="{
          // padding: '40px 0 0 120px'
        }"
      >
        <AccountInformationItem v-bind="vehicleItem"> </AccountInformationItem>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="pt-4"
        :style="{
          // padding: '40px 0 0 120px'
        }"
      >
        <AccountInformationItem v-bind="houseItem"> </AccountInformationItem>
      </v-col>
    </v-row>
  </v-container>
</template>
